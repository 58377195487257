$color-black: #000000;
$color-white: #ffffff;
$color-grey-1: #454545;
$color-grey-2: #808080;
$color-blue: #0000ff;
$color-pink: #FFBBA1;
$color-red: #FF3C00;
$color-purple: #A7ACFE;
$color-green: #62F5AC;
$color-yellow: #FCF115;

$screen-min-xs: 375px;	// Phone
$screen-min-sm: 576px;	// Phablet / Phone landscape
$screen-min-md: 768px;	// Tablet
$screen-min-lg: 992px;	// Laptop
$screen-min-xl: 1200px; // Desktop
$screen-min-xxl: 1600px; // Large Desktop

$screen-max-sm: 575px;
$screen-max-md: 767px;
$screen-max-lg: 991px;
$screen-max-xl: 1199px;
$screen-max-xxl: 1599px;

$media-min-xs: "only screen and (min-width: #{$screen-min-xs})";
$media-min-sm: "only screen and (min-width: #{$screen-min-sm})";
$media-min-md: "only screen and (min-width: #{$screen-min-md})";
$media-min-lg: "only screen and (min-width: #{$screen-min-lg})";
$media-min-xl: "only screen and (min-width: #{$screen-min-xl})";
$media-min-xxl: "only screen and (min-width: #{$screen-min-xxl})";

$media-max-sm: "only screen and (max-width: #{$screen-max-sm})";
$media-max-md: "only screen and (max-width: #{$screen-max-md})";
$media-max-lg: "only screen and (max-width: #{$screen-max-lg})";
$media-max-xl: "only screen and (max-width: #{$screen-max-xl})";
$media-max-xxl: "only screen and (max-width: #{$screen-max-xxl})";

$primary-font: "NeueHaasGroteskText Pro", sans-serif;
$grid-gutter-width: 30px;

$zIndex-bg: 10;
$zIndex-stickers: 11;
$zIndex-content: 12;
$zIndex-footer: 13;
$zIndex-siteNav: 14;
$zIndex-siteIntro: 15;