@import '../../style/variables';

.container {

	position: relative;
	
	display: block;
	width: 100%;
	min-width: 320px;

	margin-left: auto;
	margin-right: auto;

  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

	@media #{$media-min-md} {

		max-width: $screen-min-sm;
	}

	@media #{$media-min-lg} {

		max-width: $screen-min-md;
	}
	
	@media #{$media-min-xl} {

		max-width: $screen-min-lg;
	}
	
	@media #{$media-min-xxl} {

		max-width: $screen-min-xl;
	}
}