@font-face {
	font-family: 'NeueHaasGroteskText Pro';
	src: url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.eot');
	src: url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.woff2') format('woff2'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.woff') format('woff'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.ttf') format('truetype'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-75Bd.svg#NHaasGroteskTXPro-75Bd') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'NeueHaasGroteskText Pro';
	src: url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-55Rg.eot');
	src: url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-55Rg.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-55Rg.woff2') format('woff2'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-55Rg.woff') format('woff'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-55Rg.ttf') format('truetype'),
		url('../assets/fonts/neue-haas-grotesk/NHaasGroteskTXPro-55Rg.svg#NHaasGroteskTXPro-55Rg') format('svg');
	font-weight: normal;
	font-style: normal;
}