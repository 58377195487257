@import '../../style/variables';

.siteNav {

	position: fixed;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	height: 100vh;

	color: black;

	z-index: $zIndex-siteNav;
	pointer-events: none;

	overflow: hidden;
}

.navBar {

	position: absolute;
	top: 0;
	left: 0;
	transform: translate(0, 0);

	display: flex;
	width: 100%;
	justify-content: space-between;

	transition: transform 400ms;
	transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */

	z-index: 2;
	
	&:before {

		content: '';

		position: absolute;
		top: 0;
		left: 0;
		transform: translate3d(0, 0, 0);

		display: block;
		width: 100%;
		height: 80px;
		
		background-color: white;
		opacity: 1;
		
		z-index: 2;

		transition: opacity 400ms;
		transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */

		@media #{$media-min-sm} {

			height: 100px;
		}
	}

	&__isTransparent {

		&:before {

			opacity: 0;
		}
	}

	&__isOffscreen {

		transform: translate3d(0, -100%, 0);
	}

	&__controls {

		display: flex;
	}
}

.navTrigger {

	position: absolute;
	clip: rect(0, 0, 0, 0);
	
	&:checked ~ .navMenu {
		
		display: block;
	}
}

.navMenu {

	position: absolute;
	top: 0;
	left: -100%;
	transform: translate3d(0%, 0, 0);

	display: block;
	width: 100%;
	height: 100%;

	background-color: white;
	
	z-index: 1;
	pointer-events: auto;
	overflow: hidden;

	transition: transform 500ms;
	transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */

	.navTrigger:checked ~ & {
	
		transform: translate3d(100%, 0, 0);
	}

	&__wrapper {
	
		position: absolute;
		top: 0;
		left: 0;
		transform: translate3d(100%, 0, 0);
	
		display: block;
		width: 100%;
		height: 100%;
		
		transition: transform 500ms;
		transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */
	
		.navTrigger:checked ~ .navMenu & {
		
			transform: translate3d(0%, 0, 0);
		}
	}
}

.navToggle {

	display: flex;
	width: 80px;
	height: 80px;
	justify-content: center;
	align-items: center;

	z-index: 2;
	pointer-events: auto;
	cursor: pointer;
	
	@media #{$media-min-sm} {

		width: 100px;
		height: 100px;
	}
}

.navIcon {

	transform: scale(-1, 1);

	stroke: black;
	transition: stroke 400ms;

	&__isWhite {

		stroke: white;
	}

	&__path {

		stroke: inherit;
		stroke-width: 3.5;
		stroke-linecap: round;
		stroke-miterlimit: 10;
		transition-property: stroke-dashoffset, stroke-dasharray, opacity;
		transition-duration: 300ms;
		transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */
	}

	&__top {

		stroke-dasharray: 23;
		stroke-dashoffset: 0;
		opacity: 1;

		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 23;
			opacity: 0;
		}
	}

	&__middle {

		stroke-dasharray: 23;
		stroke-dashoffset: 0;

		html[class~="no-touch"] .navToggle:hover & {

			stroke-dashoffset: 23;
		}
		
		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 23;
			opacity: 0;
		}
		
		html[class~="no-touch"] .navTrigger:checked ~ .navToggle:hover & {

			stroke-dashoffset: 23;
			opacity: 0;
		}
	}

	&__middleHover {

		stroke-dasharray: 15;
		stroke-dashoffset: 15;
		opacity: 0;
		
		html[class~="no-touch"] .navToggle:hover & {

			stroke-dashoffset: 0;
			opacity: 1;
		}
		
		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 15;
			opacity: 0;
		}
	}

	&__bottom {

		stroke-dasharray: 23;
		stroke-dashoffset: 0;
		opacity: 1;

		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 0;
			opacity: 0;
		}
	}

	&__arrowTop {

		stroke-dasharray: 13.125;
		stroke-dashoffset: 13.125;
		
		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 2;
		}

		html[class~="no-touch"] .navTrigger:checked ~ .navBar .navToggle:hover & {

			stroke-dashoffset: 0;
		}
	}

	&__arrowMiddle {

		stroke-dasharray: 25.5;
		stroke-dashoffset: 25.5;

		.navToggle:hover & {

			stroke-dashoffset: 25;
		}

		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 2;
		}

		html[class~="no-touch"] .navTrigger:checked ~ .navBar .navToggle:hover & {

			stroke-dashoffset: 0;
		}
	}

	&__arrowBottom {

		stroke-dasharray: 13.125;
		stroke-dashoffset: 13.125;
		
		.navTrigger:checked ~ .navBar & {

			stroke-dashoffset: 2;
		}

		html[class~="no-touch"] .navTrigger:checked ~ .navBar .navToggle:hover & {

			stroke-dashoffset: 0;
		}
	}
}

.siteLink {

	position: relative;
	transform: translate3d(0, 0, 0);

	display: flex;
	align-items: center;
	height: 80px;

	padding: 0 $grid-gutter-width;
	
	background-color: transparent;

	z-index: 2;
	pointer-events: auto;

	transition-property: transform;
	transition-duration: 400ms;
	transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860); /* easeInOutCirc */

	@media #{$media-min-sm} {

		height: 100px;
		padding: 0 36px;
	}
	
	&__isHidden {

		@media #{$media-min-md} {
			
			transform: translate3d(0, -100%, 0);
		}
	}
	
	&__logo {
		
		position: absolute;
	
		display: block;
		width: 132px;
		height: 100%;

		opacity: 1;

		transition: opacity 200ms;

		&__isHidden {

			opacity: 0;
		}
	}
}

.navItems {

	position: relative;

	display: flex;
	width: 100%;
	height: 100%;

	flex-direction: column;
	justify-content: center;

	padding: 0;
	margin: 0;

	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	z-index: 1;
	
	@media (orientation: landscape) {

		justify-content: flex-start;
		padding: 80px 0 40px 0;
	}
	
	@media #{$media-min-sm} {

		justify-content: flex-start;
		padding: 100px 0 50px 0;
	}

	@media #{$media-min-md} {

		justify-content: center;
		padding: 0;
		
		@media (orientation: landscape) {
	
			justify-content: flex-start;
			padding: 100px 0 50px 0;
		}
	}

	@media #{$media-min-lg} {

		justify-content: center;
		padding: 0;
	}
}

.navItem {

	position: relative;

	display: block;
	padding: 30px 0 20px 0;

	text-decoration: none;

	@media (orientation: landscape) {

		padding: 20px 0 10px 0;
	}
	
	@media #{$media-min-sm} {

		padding: 30px 0 20px 0;
		
		@media (orientation: landscape) {
	
			padding: 20px 0 10px 0;
		}
	}

	@media #{$media-min-md} {

		padding: 30px 0 20px 0;
	}
	
	&__wrapper {
	
		display: block;
		margin: 0 auto;
	
		@media #{$media-min-md} {
			
			max-width: 600px;
		}
	
		@media #{$media-min-lg} {
			
			max-width: 800px;
		}
		
		@media #{$media-min-xl} {
			
			max-width: 1000px;
		}
	}
	
	&__title {

		position: relative;
	
		display: inline-block;
		margin: 0;
		padding: 5px 10px;
	
		font-size: 52px;
		font-weight: bold;
		letter-spacing: -0.3px;
		line-height: 1;
		color: black;
		text-align: left;
		text-transform: uppercase;
		word-wrap: break-word;
		
		overflow: hidden;
	
		@media #{$media-min-sm} {
	
			font-size: 70px;
			letter-spacing: -0.6px;
		}
	
		@media #{$media-min-md} {
	
			font-size: 100px;
			letter-spacing: -0.8px;
		}
	
		@media #{$media-min-lg} {
	
			font-size: 120px;
			letter-spacing: -1px;
		}

		&:before {

			content: '';

			position: absolute;
			top: 0;
			left: 0;
			transform: translate(calc(-100% - 1px), 0);

			display: block;
			width: 100%;
			height: 100%;
			
			z-index: -1;

			transition: transform 200ms 100ms;

			html[class~="no-touch"] .navItem:hover & {

				transform: translate(0%, 0);
			}

			.navItem:nth-child(4n+1) & { background-color: $color-purple; }
			.navItem:nth-child(4n+2) & { background-color: $color-pink; }
			.navItem:nth-child(4n+3) & { background-color: $color-green; }
			.navItem:nth-child(4n+4) & { background-color: $color-yellow; }
		}
	}
	
	&__label {

		position: relative;
	
		display: inline-block;
		margin: 0;
		padding: 5px 10px;
	
		font-size: 14px;
		font-weight: bold;
		letter-spacing: -0.1px;
		line-height: 1;
		color: black;
		text-align: left;
		text-transform: uppercase;
		word-wrap: break-word;

		overflow: hidden;

		&:before {

			content: '';

			position: absolute;
			top: 0;
			left: 0;
			transform: translate(calc(-100% - 1px), 0);

			display: block;
			width: 100%;
			height: 100%;
			
			z-index: -1;

			transition: transform 200ms;

			html[class~="no-touch"] .navItem:hover & {

				transform: translate(0%, 0);
			}
			
			.navItem:nth-child(4n+1) & { background-color: $color-purple; }
			.navItem:nth-child(4n+2) & { background-color: $color-pink; }
			.navItem:nth-child(4n+3) & { background-color: $color-green; }
			.navItem:nth-child(4n+4) & { background-color: $color-yellow; }
		}
	}
}