@import 'fonts';
@import 'variables';

* {
	box-sizing: border-box;
}

html, body {
	font-family: $primary-font;
	font-weight: normal;
	overflow-x: hidden;
}