@import '../../style/variables';

.siteIntro {

	position: fixed;
	top: 0;
	left: 0;

	display: none;
	width: 100vw;
	height: 100vh;

	background-color: white;

	z-index: $zIndex-siteIntro;
	pointer-events: none;

	transition-property: opacity;
	transition-duration: 200ms;

	&[class~='isVisible'] {

		display: block;
	}
	
	&[class~='isFaded'] {

		animation-name: fadeOutSiteIntro;
		animation-duration: 500ms;
		animation-fill-mode: forwards;
	}
}

@keyframes fadeOutSiteIntro {
	from { opacity: 1; }
	to { opacity: 0; }
}
